import { useMemo } from 'react';

import { t } from '@core/i18n';
import { WithStyles, withStyles } from '@core/theme/utils/with-styles';
import { Flex } from '@shared/components/flex';

import { styles } from './FilterSelect.styles';
import { MultiSelect, MultiSelectProps } from '../select/multi-select';
import { MultiSelectOnChange, SingleSelectOnChange } from '../select/Select.types';
import { SingleSelect } from '../select/single-select';

export interface FilterSelectProps extends WithStyles<typeof styles>, Omit<MultiSelectProps, 'classes' | 'onChange'> {
  label: string | React.ReactElement;
  onChange: (value: string[]) => void;
  isMultiple?: boolean;
  action?: React.ReactNode;
}

const FilterSelectComponent: React.FC<FilterSelectProps> = ({
  classes,
  label,
  onChange,
  isMultiple = true,
  action,
  value,
  ...selectProps
}) => {
  const handleMultiSelectChange: MultiSelectOnChange = (e, value) => {
    const normalizedValues = value.map((v) => String(v));

    onChange(normalizedValues);
  };

  const handleSingleSelectChange: SingleSelectOnChange = (e, value) => {
    onChange([String(value)]);
  };

  const content = useMemo(() => {
    if (isMultiple) {
      return (
        <MultiSelect
          onChange={handleMultiSelectChange}
          placeholder={t('xtextx_ellipsis', { text: t('please_select') })}
          classes={{ root: classes.select }}
          value={value}
          {...selectProps}
        />
      );
    }

    return (
      <SingleSelect
        onChange={handleSingleSelectChange}
        placeholder={t('xtextx_ellipsis', { text: t('please_select') })}
        classes={{ root: classes.select }}
        value={value?.[0]}
        {...selectProps}
      />
    );
  }, [handleMultiSelectChange, handleSingleSelectChange, value]);

  return (
    <Flex className={classes.root}>
      <Flex autoWidth={false} justifyContent="space-between">
        <span className={classes.label}>{label}</span>
        {action}
      </Flex>
      {content}
    </Flex>
  );
};

// TODO: We should rename this to MultiSelect. Doesn't need to be tied to a filter select.
export const FilterSelect = withStyles(styles)(FilterSelectComponent);
