import { createStyles } from '@core/theme/utils/create-styles';

export const styles = () =>
  createStyles({
    root: {},
    logo: {
      cursor: 'pointer',
      display: 'block',
      height: 35,
      width: 100,
    },
  });
