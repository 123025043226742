import { Theme } from '@material-ui/core';

import { colors } from '@core/theme/units/palette';
import { alpha } from '@core/theme/utils/alpha';
import { createStyles } from '@core/theme/utils/create-styles';

export const styles = ({ spacing }: Theme) =>
  createStyles({
    root: {
      alignItems: 'center',
      borderRadius: 6,
      color: alpha(colors.white.lightest, 0.65),
      display: 'flex',
      flexDirection: 'row',
      fontSize: 14,
      gap: 8,
      height: 40,
      margin: 0,
      maxWidth: 190,
      padding: spacing(2.5, 2),
      stroke: alpha(colors.white.lightest, 0.65),
      userSelect: 'none',

      '&:hover': {
        backgroundColor: alpha(colors.white.lightest, 0.15),
        color: colors.white.lightest,
        cursor: 'pointer',
        stroke: colors.white.lightest,
      },
    },
    avatarRoot: {
      flex: '0 0 auto',
      height: 20,
      minWidth: 20,
      width: 20,
    },
    avatar: {
      border: 'none',
      marginBottom: 0,
    },
    avatarNoImage: {
      border: 'none',
    },
    name: {
      flex: '1 1 auto',
      fontSize: 14,
      fontWeight: 500,
      maxWidth: 114,
      overflow: 'hidden',
      position: 'relative',
      textOverflow: 'ellipsis',
      transition: 'all 0.4s ease',
      whiteSpace: 'nowrap',
    },
    noImageInitials: {
      fontSize: '9.6px',
    },
    iconContainer: {
      alignItems: 'center',
      display: 'flex',
      flex: '0 0 auto',
    },
    menuIcon: {
      fontSize: 20,

      '& path': {
        stroke: 'inherit',

        '&:hover': {
          cursor: 'inherit',
        },
      },
    },
    menuPaper: {
      borderRadius: 8,
      border: '1px solid #EAECF0', //TODO change after creating new palette
      boxShadow: '0px 12px 24px -4px rgba(16, 24, 40, 0.10), 0px 4px 6px -2px rgba(16, 24, 40, 0.03)',
      left: '198px !important',
      maxWidth: 250,
    },
    menuList: {
      padding: spacing(2),
    },
    menuItem: {
      backgroundColor: 'transparent !important',
      borderRadius: 6,
      color: '#344154', //TODO change after creating new palette
      fontSize: 14,
      fontWeight: 500,
      maxWidth: '100%',
      padding: 0,

      '& a': {
        color: 'inherit',
        width: '100%',

        '&:hover': {
          color: 'inherit',
        },
      },

      '& i': {
        color: '#7e8899', //TODO change after creating new palette
      },

      '& svg': {
        color: '#7e8899', //TODO change after creating new palette
      },

      '& svg path': {
        stroke: 'currentColor',
      },

      '&:hover': {
        backgroundColor: `${colors.kato.grey[100]} !important`,
        color: colors.kato.navy[950],
        cursor: 'pointer',

        '& i': {
          color: 'currentColor',
        },

        '& svg': {
          color: 'currentColor',
        },

        '& svg path': {
          stroke: 'currentColor',
        },
      },
    },
    label: {
      color: 'inherit',
      fontSize: 14,
      fontWeight: 500,
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
    },
    divider: {
      borderTop: '1px solid #EAECF0', //TODO change after creating new palette
      margin: spacing(0.25, 0),
    },
    itemIconContainer: {
      '& svg': {
        fontSize: 16,
      },

      '& i': {
        color: '#7E8899', //TODO change after creating new palette
      },

      '& svg path': {
        stroke: '#7E8899', //TODO change after creating new palette

        '&:hover': {
          stroke: 'inherit',
        },
      },
    },
    itemLink: {
      display: 'flex',
      gap: 9,
      padding: spacing(2.5, 2),
    },
    layerIcon: {
      display: 'flex',
      alignItems: 'center',
      fontSize: `16px !important`,
      color: 'inherit',
      blockSize: '16px',

      '&:hover': {
        color: 'inherit',
      },
    },
  });
