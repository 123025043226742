import { Theme } from '@core/theme/types/main';
import { colors } from '@core/theme/units/palette';
import { alpha } from '@core/theme/utils/alpha';
import { createStyles } from '@core/theme/utils/create-styles';

const contentShadowHeight = 83;

export const styles = ({ spacing, zIndex }: Theme) =>
  createStyles({
    root: {
      backgroundColor: '#201E42', // TODO: Change after palette updated
      color: 'white', // TODO remove after content implementation
      display: 'flex',
      flexDirection: 'column',
      position: 'relative',

      '&:hover': {
        '& $collapseBtn': {
          opacity: 1,
        },
      },
    },
    rootCollapsed: {
      position: 'relative',

      '& $collapseBtn': {
        paddingLeft: spacing(3.5),
      },

      '&::after': {
        content: '""',
        position: 'absolute',
        backgroundColor: '#201E42', // TODO: Change after palette updated
        height: '100%',
        bottom: 0,
        left: 0,
        top: 0,
        right: 0,
      },
    },

    impersonateContainer: {},
    impersonateTooltip: {
      backgroundColor: colors.black.darker,
      fontSize: 13,
      marginTop: spacing(3),
      padding: spacing(5, 4),
      textAlign: 'center',
    },
    impersonateTooltipArrow: {
      fontSize: 13,
      top: `0 !important`,

      '&::before': {
        backgroundColor: colors.black.darker,
        border: `2px solid ${colors.black.darker}`,
      },
    },
    impersonateLink: {
      backgroundImage: `url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAGCAIAAAAOtlpdAAAANElEQVQoz2P4etGEEnRxi0lHFdXQ8slQYxkGoZsochbt3ES+s2jqJjKdRWs3keMsOrgJiAB4ItlirqqJGgAAAABJRU5ErkJggg==) !important`,
      backgroundPosition: '-8px 0',
      backgroundRepeat: 'repeat-x',
      backgroundSize: '25px 7px',
      display: 'block',
      flex: '0 0 auto',
      height: 7,
    },

    header: {
      flex: '0 0 auto',
      overflow: 'hidden',
      padding: spacing(4, 3, 4, 5),
      width: '100%',
    },
    upgradeCta: {
      marginTop: spacing(4),
    },
    contentContainer: {
      flex: '1 1 auto',
      width: '100%',
      overflow: 'hidden',

      position: 'relative',

      '&::before, &::after': {
        content: '""',
        position: 'absolute',
        left: 10,
        opacity: 0,
        pointerEvents: 'none',
        right: 10,
      },

      '&::after': {
        content: '""',
        position: 'absolute',
        background: 'linear-gradient(359deg, #201E42 11.15%, rgba(32, 30, 66, 0.00) 88.15%)',
        height: contentShadowHeight,
        bottom: 0,
        opacity: 1,
      },
    },
    content: {
      height: '100%',
      overflowY: 'auto',
      padding: spacing(2, 3, 4, 3),
      paddingBottom: contentShadowHeight,
    },
    collapseBtn: {
      border: `1px solid #D1D6DF`, // TODO: Change after palette updated
      borderRadius: 6,
      boxShadow: '0px 1px 2px 0px rgba(16, 24, 40, 0.07)',
      opacity: 0,
      position: 'absolute',
      right: -13,
      top: 32,
      transition: 'background-color 0.3s ease, border 0.3s ease',
      paddingLeft: spacing(3.75),
      width: 24,
      zIndex: zIndex.drawer,

      '& svg path': {
        stroke: '#7E8899', // TODO: Change after palette updated
      },

      '&:hover': {
        backgroundColor: '#FF4A6B !important', // TODO: Change after palette updated
        borderColor: '#FF4A6B', // TODO: Change after palette updated

        '& svg path': {
          stroke: `${colors.white.lightest} !important`,
        },
      },
    },
    collapseBtnVisible: {
      opacity: 1,
    },
    collapseLine: {
      position: 'absolute',
      top: 0,
      bottom: 0,
      right: -12,
      width: 24,
      backgroundColor: 'transparent',
      cursor: 'ew-resize',
    },
    marketplaceSectionContainer: {
      margin: spacing(4, 2.5),
      height: 1,
      maxWidth: '100%',
      backgroundColor: alpha(colors.white.lightest, 0.25),
    },
    marketplaceSectionLabel: {
      fontSize: 12,
      fontWeight: 600,
      color: colors.white.lightest,
      padding: spacing(0, 2.5, 2),
      userSelect: 'none',
    },
    footer: {
      flex: '0 0 auto',
      padding: spacing(0, 3, 4),
    },
    footerDivider: {
      height: 1,
      marginBottom: spacing(4),
      maxWidth: '100%',
      backgroundColor: alpha(colors.white.lightest, 0.25),
    },
    hiddenContent: {
      visibility: 'hidden',
    },
  });
