export const layoutHorizontalSpacing = 6;
export const layoutHorizontalTabletSpacing = 4;
export const layoutHorizontalMobileSpacing = 2;

export const layoutVerticalSpacing = 6;
export const layoutVerticalTabletSpacing = 4;
export const layoutVerticalMobileSpacing = 2;

export const menuVerticalSpacing = 4;

export const pageTopSpacing = 6;
export const pageBottomSpacing = 6;
export const pageBottomJumpToSpacing = 50;
