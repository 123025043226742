import { Link } from '@material-ui/core';
import cx from 'classnames';
import { FC, ReactNode, useMemo } from 'react';
import { Link as RouterLink, useLocation } from 'react-router-dom';

import { WithStyles, withStyles } from '@core/theme/utils/with-styles';
import { Badge, BADGE_SIZE, BadgeProps } from '@shared/components/badge';
import { Flex } from '@shared/components/flex';
import { ZapIcon } from '@shared/icons/zap';
import { checkIsActive } from '@shared/utils/common';

import { styles } from './SideNavigationItem.styles';

export interface SideNavigationItemProps extends WithStyles<typeof styles> {
  badge?: Omit<BadgeProps, 'classes'>;
  startIcon: ReactNode;
  to: string;
  title: string;
}

const SideNavigationItemComponent: FC<SideNavigationItemProps> = ({ badge, classes, startIcon, to, title }) => {
  const { pathname } = useLocation();

  const linkContent = useMemo(
    () => (
      <Flex autoWidth={false} wrap="nowrap" classes={{ root: classes.linkContainer }}>
        <span className={classes.icon}>{startIcon}</span>
        <span className={classes.title}>{title}</span>
        {!!badge && (
          <Badge
            size={BADGE_SIZE.small}
            startIcon={<ZapIcon classes={{ root: classes.badgeIcon }} />}
            {...badge}
            classes={{ badge: classes.badge }}
          />
        )}
      </Flex>
    ),
    [badge, classes, startIcon, title]
  );

  if (to.includes('http')) {
    return (
      <Link
        href={to}
        classes={{
          root: cx(classes.link, classes.externalLink),
        }}
      >
        {linkContent}
      </Link>
    );
  }

  return (
    <RouterLink to={to} className={cx(classes.link, { [classes.linkActive]: checkIsActive(pathname, to) })}>
      {linkContent}
    </RouterLink>
  );
};

export const SideNavigationItem = withStyles(styles)(SideNavigationItemComponent);
