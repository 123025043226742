import { FC, useMemo } from 'react';

import { t } from '@core/i18n';
import { WithStyles, withStyles } from '@core/theme/utils/with-styles';
import { Button } from '@shared/components/button';
import { FilterCounter } from '@shared/components/filter-counter';
import { CloudIcon } from '@shared/icons/cloud';
import { FiltersIcon } from '@shared/icons/filters';
import { UserIcon } from '@shared/icons/user';

import { styles } from './FilterButton.styles';

export enum FilterButtonTypes {
  filters = 'filters',
  moreFilters = 'moreFilters',
  source = 'source',
  teams = 'teams',
}

export interface FilterButtonProps extends WithStyles<typeof styles> {
  count: number;
  type?: FilterButtonTypes;
  onClick: () => void;
}

const FilterButtonComponent: FC<FilterButtonProps> = ({
  classes,
  count,
  onClick,
  type = FilterButtonTypes.moreFilters,
}) => {
  const button = useMemo(() => {
    let text = null;
    let icon = null;

    switch (type) {
      case FilterButtonTypes.filters: {
        text = t('filters');
        icon = <FiltersIcon classes={{ root: classes.filtersIcon }} />;
        break;
      }

      case FilterButtonTypes.moreFilters: {
        text = t('more_filters_1');
        icon = <FiltersIcon classes={{ root: classes.filtersIcon }} />;
        break;
      }

      case FilterButtonTypes.source: {
        text = t('source');
        icon = <CloudIcon classes={{ root: classes.sourceIcon }} />;
        break;
      }

      case FilterButtonTypes.teams: {
        text = t('teams');
        icon = <UserIcon classes={{ root: classes.teamsIcon }} />;
        break;
      }
    }

    return (
      <Button
        onClick={onClick}
        color="default"
        variant="outlined"
        text={text}
        startIcon={icon}
        endIcon={<FilterCounter count={count} classes={{ root: classes.counterRoot, count: classes.counterCount }} />}
        classes={{ root: classes.root }}
      />
    );
  }, [count, onClick, type]);

  return button;
};

export const FilterButton = withStyles(styles)(FilterButtonComponent);
