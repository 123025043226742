import { CancelOutlined as CancelIcon } from '@material-ui/icons';
import { debounce } from 'lodash';

import { WithStyles, withStyles } from '@core/theme/utils/with-styles';
import { Button } from '@shared/components/button';
import { Flex } from '@shared/components/flex';
import { TextField, TextFieldProps } from '@shared/components/text-field';
import { SearchIcon } from '@shared/icons/search';

import { styles } from './CollapsingSearch.styles';

export interface CollapsingSearchProps extends WithStyles<typeof styles> {
  inputProps?: Omit<TextFieldProps, 'classes'>;
  placeholder: string;
  search?: string;
  searchOpen: boolean;
  onSearchChange: (search: string) => void;
  setSearchOpen: (searchOpen: boolean) => void;
}

const CollapsingSearchComponent: React.FC<CollapsingSearchProps> = ({
  classes,
  inputProps,
  placeholder,
  search,
  searchOpen,
  onSearchChange,
  setSearchOpen,
}) => {
  const [localSearch, setLocalSearch] = React.useState<string>(search || '');

  React.useEffect(() => {
    setLocalSearch(search || '');

    if (search) {
      setSearchOpen(true);
    }
  }, [search]);

  const handleSearchBtnClick = () => {
    setSearchOpen(true);
  };

  const handleSearchFieldBlur = () => {
    if (!localSearch) {
      setSearchOpen(false);
    }
  };

  const changeLocalSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setLocalSearch(e.target.value);

    debouncedSearch(e.target.value);
  };

  const debouncedSearch = React.useCallback(
    debounce((value) => onSearchChange(value), 350),
    [onSearchChange]
  );

  const handleClearField = () => {
    setLocalSearch('');
    setSearchOpen(false);

    onSearchChange('');
  };

  const content = React.useMemo(() => {
    if (searchOpen) {
      const endAdornment = localSearch ? (
        <CancelIcon onClick={handleClearField} classes={{ root: classes.clearIcon }} />
      ) : null;

      return (
        <TextField
          autoFocus
          value={localSearch}
          onBlur={handleSearchFieldBlur}
          onChange={changeLocalSearch}
          placeholder={placeholder}
          classes={{ input: classes.searchFieldInput }}
          InputProps={{ endAdornment: endAdornment }}
          {...inputProps}
        />
      );
    }

    return (
      <Button
        color="default"
        variant="outlined"
        text={<SearchIcon classes={{ root: classes.searchIcon }} />}
        onClick={handleSearchBtnClick}
        classes={{ root: classes.searchBtn }}
      />
    );
  }, [classes, searchOpen, localSearch, handleSearchBtnClick]);

  return (
    <Flex alignItems="center" wrap="nowrap" classes={{ root: classes.root }}>
      {content}
    </Flex>
  );
};

export const CollapsingSearch = withStyles(styles)(CollapsingSearchComponent);
