import cx from 'classnames';
import { FC, ReactNode } from 'react';

import { WithStyles, withStyles } from '@core/theme/utils/with-styles';

import { styles } from './PageHeader.styles';

export interface PageHeaderProps extends WithStyles<typeof styles> {
  actions?: ReactNode;
  navigation?: ReactNode;
  title: string | ReactNode;
}

const PageHeaderComponent: FC<PageHeaderProps> = ({ actions, classes, navigation, title }) => {
  return (
    <div className={classes.root}>
      <div
        className={cx(
          classes.header,
          { [classes.headerWithActions]: actions },
          { [classes.headerNoNavigation]: !navigation }
        )}
      >
        <h1 className={classes.headerTitle}>{title}</h1>
        {actions}
      </div>
      {navigation && <div className={classes.navigationContainer}>{navigation}</div>}
    </div>
  );
};

export const PageHeader = withStyles(styles)(PageHeaderComponent);
