import { Theme } from '@core/theme/types/main';
import { colors } from '@core/theme/units/palette';
import { createStyles } from '@core/theme/utils/create-styles';

export const styles = ({ shape }: Theme) =>
  createStyles({
    root: {
      width: '100%',
    },
    label: {
      color: colors.grey.darker,
      fontSize: 14,
      fontWeight: 500,
      marginBottom: 10,
      lineHeight: 'normal',
    },
    select: {
      width: '100%',
      height: 36,
      borderRadius: shape.borderRadius,
    },
  });
