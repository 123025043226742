import { Theme } from '@core/theme/types/main';
import { colors } from '@core/theme/units/palette';
import { alpha } from '@core/theme/utils/alpha';
import { createStyles } from '@core/theme/utils/create-styles';

export const styles = ({ shape }: Theme) =>
  createStyles({
    root: {},
    button: {
      borderRadius: 0,
    },
    firstButton: {
      borderTopLeftRadius: shape.borderRadius,
      borderBottomLeftRadius: shape.borderRadius,
    },
    lastButton: {
      borderTopRightRadius: shape.borderRadius,
      borderBottomRightRadius: shape.borderRadius,
    },
    activeButton: {
      backgroundColor: alpha(colors.blue.dark, 0.15),
    },
  });
