import { Theme } from '@core/theme/types/main';
import { colors } from '@core/theme/units/palette';
import { createStyles } from '@core/theme/utils/create-styles';
import { NoDataTheme } from '@shared/components/no-data/NoData';

export const styles = ({ spacing }: Theme) =>
  createStyles({
    root: {
      width: '100%',
      padding: spacing(20, 0),
      textAlign: 'center',
    },
    header: {},
    text: {},
    icon: {
      '& i': {
        blockSize: '90px',
        color: colors.grey.icon,
        display: 'inline-block',
        fontSize: 85,
        marginTop: -15,
      },
    },
    [NoDataTheme.Tile]: {
      '& $header': {
        marginTop: 30,
        marginBottom: 0,
        fontSize: 16,
      },
      '& $text': {
        marginTop: 10,
        marginBottom: 0,
        fontSize: 14,
      },
    },
    [NoDataTheme.Page]: {
      '& $header': {
        color: colors.black.darker,
        fontSize: 22,
        marginBottom: spacing(6),
      },
      '& $text': {
        marginBottom: spacing(6),
        fontSize: 18,
        color: colors.grey.main,
      },
    },
  });
