import { Theme } from '@core/theme/types/main';
import { colors } from '@core/theme/units/palette';
import { createStyles } from '@core/theme/utils/create-styles';

export const styles = ({ spacing }: Theme) =>
  createStyles({
    root: {
      maxWidth: '100%',
    },
    rootInfo: {
      color: '#515D70', //TODO replace after creating new palette
    },
    rootError: {
      color: colors.red.darkest,
    },
    rootWarning: {
      color: '#B54708', // TODO: Change after palette updated
    },
    icon: {
      color: 'inherit',
      fontSize: 13,
      flex: '0 0 auto',
    },
    text: {
      color: 'inherit',
      flex: '1 1 auto',
      fontSize: 12,
      fontWeight: 500,
      lineHeight: '20px',
      overflow: 'hidden',
      paddingLeft: spacing(1),
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
    },
  });
