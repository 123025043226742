import { Theme } from '@material-ui/core';

import { colors } from '@core/theme/units/palette';
import { createStyles } from '@core/theme/utils/create-styles';

export const styles = ({ spacing }: Theme) =>
  createStyles({
    root: {},
    feedbackContainer: {
      gap: 8,
      width: '100%',
      overflow: 'hidden',
      minHeight: 42,
      backgroundColor: '#EAECF0', //TODO change after creating new palette
      padding: spacing(2, 4),
      borderRadius: 6,
    },
    commentIcon: {
      fontSize: 15,
    },
    commentStatus: {
      color: '#101828', // TODO: Change after palette updated
      fontWeight: 600,
      fontSize: 14,
    },
    commentText: {
      flex: 1,
      maxWidth: 300,
      background: colors.white.lightest,
      padding: spacing(1, 3),
      color: '#515D70', // TODO: Change after palette updated
      fontWeight: 500,
      fontSize: 12,
      overflow: 'hidden',
      borderRadius: spacing(1.5, 1.5, 1.5, 0),
    },
    feedbackPendingContainer: {
      gap: 8,
      overflow: 'hidden',
      minHeight: 42,
      padding: spacing(2, 4),
      background: '#FFF5EB', // TODO: Change after palette updated
      borderRadius: 6,
    },
    commentPendingStatus: {
      color: '#B54708', // TODO: Change after palette updated
      fontWeight: 600,
      fontSize: 14,
      minWidth: 130,
    },
    commentPendingText: {
      color: '#B54708', // TODO: Change after palette updated
      fontWeight: 400,
      fontSize: 14,
    },
  });
