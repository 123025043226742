import cx from 'classnames';
import { FC, ReactElement } from 'react';

import { WithStyles, withStyles } from '@core/theme/utils/with-styles';
import { Flex } from '@shared/components/flex';

import { styles } from './NoData.styles';

export interface NoDataProps extends WithStyles<typeof styles> {
  icon?: ReactElement;
  header?: string;
  text?: string;
  theme?: NoDataTheme;
}

export enum NoDataTheme {
  Tile = 'tile',
  Page = 'page',
}

const NoDataComponent: FC<NoDataProps> = ({ classes, header, text, icon, theme = NoDataTheme.Page, children }) => {
  return (
    <Flex direction="column" alignItems="center" className={cx(classes.root, classes[theme])}>
      {icon && <span className={classes.icon}>{icon}</span>}
      {header && <span className={classes.header}>{header}</span>}
      {text && <span className={classes.text}>{text}</span>}
      {children}
    </Flex>
  );
};

export const NoData = withStyles(styles)(NoDataComponent);
