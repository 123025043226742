import cx from 'classnames';
import { ChangeEvent, FC, useMemo } from 'react';

import { t } from '@core/i18n';
import { withStyles, WithStyles } from '@core/theme/utils/with-styles';
import { Button, ButtonProps } from '@shared/components/button';
import { Flex } from '@shared/components/flex';
import { ChevronLeftIcon } from '@shared/icons/chevron-left';
import { ChevronRightIcon } from '@shared/icons/chevron-right';

import { styles } from './Pagination.styles';

export interface PaginationProps extends WithStyles<typeof styles> {
  from?: number;
  to?: number;
  currentPage?: number;
  totalItems?: number;
  totalPages?: number;
  align?: 'left' | 'right';
  onPageChange: (page: number) => void;
  disabled?: boolean
}

export const defaultPaginationLimit = 25;

const PaginationComponent: FC<PaginationProps> = ({
  classes,
  from,
  to,
  totalItems,
  currentPage,
  totalPages,
  align = 'left',
  onPageChange,
  disabled
}) => {
  

  const handlePrevPageClick = (e: ChangeEvent<any>) => {
    e.persist();

    const { current } = e.currentTarget.dataset;
    onPageChange(Number(current) - 1);
  };

  const handleNextPageClick = (e: ChangeEvent<any>) => {
    e.persist();

    const { current } = e.currentTarget.dataset;
    onPageChange(Number(current) + 1);
  };

  const buttonConfig = useMemo(() => {
    const config: Array<ButtonProps> = [
      {
        text: <ChevronLeftIcon />,
        disabled: disabled || currentPage === 1 || !from,
        onClick: handlePrevPageClick,
      },
      {
        text: <ChevronRightIcon />,
        disabled: disabled || currentPage === totalPages || !to,
        onClick: handleNextPageClick,
      },
    ];

    return config;
  }, [classes, currentPage, totalPages]);

  const paginationCountText = useMemo(
    () => (
      <span>
        {from || 0} - {to || 0}
        <span className={classes.paginationOf}>{t('of')}</span>
        {totalItems || 0}
      </span>
    ),
    [from, to, totalItems]
  );

  return (
    <Flex alignItems="center" wrap="nowrap" className={cx(classes.root, { [classes.alignRight]: align === 'right' })}>
      <div className={classes.paginationInfo}>
        <span className={classes.paginationInfoCounts}>{paginationCountText}</span>
      </div>
      <Flex wrap="nowrap" className={classes.paginationActions}>
        {buttonConfig.map((btnProps, index) => (
          <Button
            key={index}
            variant="outlined"
            size="small"
            color="default"
            data-current={currentPage}
            classes={{
              root: classes.button,
              label: classes.buttonLabel,
              disabled: classes.pageDisabled,
            }}
            {...btnProps}
          />
        ))}
      </Flex>
    </Flex>
  );
};

export const Pagination = withStyles(styles)(PaginationComponent);
