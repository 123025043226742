import { Link as MuiLink } from '@material-ui/core';
import { FC } from 'react';
import { Link as RouterLink } from 'react-router-dom';

import { WithStyles, withStyles } from '@core/theme/utils/with-styles';

import { styles } from './Link.styles';

export interface LinkProps extends WithStyles<typeof styles> {
  to: string;
}

const LinkComponent: FC<LinkProps> = ({ classes, children, to }) => {
  if (to.includes('http')) {
    return (
      <MuiLink classes={{ root: classes.root }} href={to}>
        {children}
      </MuiLink>
    );
  }

  return (
    <RouterLink className={classes.root} to={to}>
      {children}
    </RouterLink>
  );
};

export const Link = withStyles(styles)(LinkComponent);
