import { Theme } from '@core/theme/types/main';
import { colors } from '@core/theme/units/palette';
import { createStyles } from '@core/theme/utils/create-styles';

export const styles = ({ spacing }: Theme) =>
  createStyles({
    root: {
      marginBottom: spacing(5),
    },
    label: {
      color: colors.grey.darker,
      fontSize: 14,
      fontWeight: 500,
      marginBottom: spacing(3),
      lineHeight: 'normal',
    },
    radios: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'flex-start',
    },
    radiosControl: {
      marginBottom: 14,
    },
    periodPicker: {
      flex: 1,
    },
    periodTo: {
      marginLeft: spacing(2),
    },
    picker: {
      '& .MuiOutlinedInput-adornedStart': {
        width: '100%',
        height: 36,
        paddingLeft: 0,
        backgroundColor: colors.white.lightest,

        '& .MuiInputAdornment-positionStart': {
          marginRight: 0,

          '& .MuiIconButton-root': {
            padding: 6,

            '& .MuiIconButton-label': {
              '& .MuiSvgIcon-root': {
                fontSize: 16,
              },
            },
          },
        },
      },

      '& .MuiOutlinedInput-inputAdornedStart': {
        width: 95,
        fontSize: 13,
      },
    },
    periodLabel: {
      color: colors.grey.main,
      marginRight: spacing(2),
      fontWeight: 500,
    },
    daysTextFieldRoot: {
      marginBottom: 0,
    },
    daysTextField: {
      width: 175,
    },
    daysTextFieldInput: {
      height: 36,
    },
    cardLabel: {
      width: 60,
      height: 36,
    },
  });
