import cx from 'classnames';

import { withStyles, WithStyles } from '@core/theme/utils/with-styles';
import { Button } from '@shared/components/button';
import { Flex } from '@shared/components/flex';

import { styles } from './ViewButtons.styles';

export interface ViewButtonsConfig<T> {
  text: string;
  view: T;
  access?: boolean;
  accessTooltip?: string;
}

export interface ViewButtonsProps extends WithStyles<typeof styles> {
  onClick: (view: any) => void;
  activeView: any;
  buttonsConfig: ViewButtonsConfig<any>[];
  disabled?: boolean;
}

const ViewButtonsComponent: React.FC<ViewButtonsProps> = ({
  classes,
  // TODO: This should accept a views param, e.g. like so:
  // views={[
  //   { type: Views.table, tooltip: 'Tooltip for table', disabled: true },
  //   { type: Views.board },
  // ]}
  // WHY: To enforce consistent labeling across the app + reduce code redundancy
  buttonsConfig,
  activeView,
  onClick,
  disabled,
}) => {
  return (
    <Flex direction="row" wrap="nowrap" className={classes.root}>
      {buttonsConfig.map(({ text, view, access = true, accessTooltip = '' }, idx) => (
        <Button
          key={text}
          disabled={disabled || !access}
          text={text}
          tooltip={accessTooltip}
          tooltipPlacement="top"
          data-view={view}
          variant="outlined"
          color="primary"
          onClick={activeView == view ? undefined : () => onClick(view)}
          classes={{
            root: cx(
              classes.button,
              { [classes.activeButton]: activeView === view },
              { [classes.firstButton]: idx === 0 },
              { [classes.lastButton]: idx == buttonsConfig.length - 1 }
            ),
          }}
        />
      ))}
    </Flex>
  );
};

export const ViewButtons = withStyles(styles)(ViewButtonsComponent);
