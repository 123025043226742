import { createStyles } from '@core/theme/utils/create-styles';

export const styles = () =>
  createStyles({
    root: {
      width: 102,
    },
    select: {},
    container: {}
  });
