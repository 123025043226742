import { MenuItem } from '@material-ui/core';
import { ClickAwayListener } from '@material-ui/core';
import { ExpandLess as ExpandLessIcon, ExpandMore as ExpandMoreIcon } from '@material-ui/icons';
import cx from 'classnames';
import { FC, ReactNode, useMemo, useState } from 'react';

import { WithStyles, withStyles } from '@core/theme/utils/with-styles';
import { Button, ButtonProps } from '@shared/components/button';
import { Flex } from '@shared/components/flex';
import { Tooltip } from '@shared/components/tooltip';

import { styles } from './Dropdown.styles';

export interface DropdownComponentProps extends WithStyles<typeof styles> {
  items: IDropdownItem[];
  mainBtnText: string;
  buttonProps?: Omit<ButtonProps, 'classes'>;
  color?: 'primary' | 'secondary';
  position?: DropDownPosition;
  disabled?: boolean;
  noIcon?: boolean;
}
export interface IDropdownItem {
  disabled?: boolean;
  divider?: boolean;
  endIcon?: ReactNode;
  icon?: ReactNode;
  text: string;
  tooltip?: string;
  onClick?: () => void;
}

export enum DropDownPosition {
  BottomRight = 'bottomRight',
  BottomLeft = 'bottomLeft',
  TopRight = 'topRight',
  TopLeft = 'topLeft',
}

const DropdownComponent: FC<DropdownComponentProps> = ({
  classes,
  items,
  mainBtnText,
  buttonProps,
  color = 'primary',
  noIcon = false,
  position = DropDownPosition.BottomRight,
  disabled = false,
}) => {
  const [open, setOpen] = useState(false);

  const handleClick = () => {
    if (disabled) {
      return;
    }
    setOpen(!open);
  };

  const handleClose = () => {
    if (!open) {
      return;
    }

    setOpen(false);
  };

  const handleActionClick = (el: IDropdownItem) => {
    setOpen(false);
    if (el.onClick) {
      el.onClick();
    }
  };

  const isTop = useMemo(() => {
    const top = [DropDownPosition.TopLeft, DropDownPosition.TopRight];

    return top.includes(position);
  }, [position]);

  const isLeft = useMemo(() => {
    const top = [DropDownPosition.TopLeft, DropDownPosition.BottomLeft];

    return top.includes(position);
  }, [position]);

  const dropdownIcon = useMemo(() => {
    if (noIcon) {
      return undefined;
    }

    return isTop
      ? !disabled && <ExpandLessIcon classes={{ root: classes.expandIcon }} />
      : !disabled && <ExpandMoreIcon classes={{ root: classes.expandIcon }} />;
  }, [noIcon, isTop, disabled]);
  return (
    <ClickAwayListener onClickAway={handleClose}>
      <div className={cx(classes.wrapper, { [classes.dropdownOpen]: open })}>
        <Button
          {...(color === 'secondary' && {
            color: 'default',
            variant: 'outlined',
          })}
          {...buttonProps}
          onClick={handleClick}
          classes={{
            root: cx(classes.root, { [classes[position]]: open }, { [classes.disabled]: disabled }),
            label: cx(classes.buttonLabel, { [classes.buttonLabelNoIcon]: noIcon }),
            startIcon: classes.startIcon,
          }}
        >
          {mainBtnText}
          {dropdownIcon}
        </Button>
        <div
          className={cx(
            classes.menu,
            { [classes.menuTop]: isTop },
            { [classes.menuLeft]: isLeft, [classes.menuOpen]: open }
          )}
        >
          {items.map((el: IDropdownItem, index: number) => {
            const itemContent = (
              <>
                <Flex wrap="nowrap" alignItems="center">
                  {el?.icon}
                  <div className={cx(classes.itemText, { [classes.itemTextMargin]: !!el?.icon })}>{el.text}</div>
                </Flex>
                {el.endIcon}
              </>
            );

            if (el.divider) {
              return <div key={`divider${index}`} className={classes.divider} />;
            }

            return (
              <MenuItem
                disableRipple
                onClick={el?.disabled ? undefined : () => handleActionClick(el)}
                key={`${el.text}:${index}`}
                classes={{ root: cx(classes.item, { [classes.itemDisabled]: el?.disabled }) }}
              >
                {el.tooltip ? (
                  <Tooltip title={el.tooltip} placement="right">
                    <div>{itemContent}</div>
                  </Tooltip>
                ) : (
                  itemContent
                )}
              </MenuItem>
            );
          })}
        </div>
      </div>
    </ClickAwayListener>
  );
};

export const Dropdown = withStyles(styles)(DropdownComponent);
