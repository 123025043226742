import { Theme } from '@core/theme/types/main';
import { colors } from '@core/theme/units/palette';
import { alpha } from '@core/theme/utils/alpha';
import { createStyles } from '@core/theme/utils/create-styles';

export const styles = ({ spacing }: Theme) =>
  createStyles({
    root: {},
    icon: {
      height: 31,
      width: 35,
      marginRight: spacing(2),
      cursor: 'pointer',
    },
    textIcon: {
      cursor: 'pointer',
      height: 16,
      marginBottom: 3,
      marginTop: 1,
      width: 122,
    },
    poweredBy: {
      color: alpha(colors.white.lightest, 0.6),
      fontSize: 10,
      fontWeight: 500,
      lineHeight: 1,
      userSelect: 'none',
    },
    poweredByLink: {
      color: colors.white.lightest,
      paddingLeft: spacing(1),
    },
  });
