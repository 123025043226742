import { createStyles } from '@core/theme/utils/create-styles';

export const styles = () =>
  createStyles({
    root: {
      height: '100%',
      minHeight: 400,
      maxHeight: '80vh',
    },
    message: {
      minHeight: 60,
      paddingBottom: 0,
    },
    heading: {
      fontSize: 18,
      textTransform: 'uppercase',
      letterSpacing: '0.9px',
    },
  });
