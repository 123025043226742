import { Link } from '@material-ui/core';
import { FC } from 'react';

import { getBaseUrl } from '@core/base-url';
import { WithStyles, withStyles } from '@core/theme/utils/with-styles';
import { KatoLogoIcon } from '@shared/icons/kato-logo';

import { styles } from './KatoNavigationLogo.styles';

export interface KatoNavigationLogoProps extends WithStyles<typeof styles> {
  onClickLogo: () => void;
}

const KatoNavigationLogoComponent: FC<KatoNavigationLogoProps> = ({ classes, onClickLogo }) => {
  // TODO: We should use LinkRenderer once its merged in
  return (
    <Link classes={{ root: classes.root }} href={`${getBaseUrl()}#/dashboard`}>
      <KatoLogoIcon classes={{ root: classes.logo }} onClick={onClickLogo} />
    </Link>
  );
};

export const KatoNavigationLogo = withStyles(styles)(KatoNavigationLogoComponent);
