import cx from 'classnames';
import { FC } from 'react';

import { colors } from '@core/theme/units/palette';
import { WithStyles, withStyles } from '@core/theme/utils/with-styles';
import { Flex } from '@shared/components/flex';
import { AlertCircleIcon } from '@shared/icons/alert-circle';
import { AlertTriangleIcon } from '@shared/icons/alert-triangle';

import { styles } from './FieldHelperText.styles';

export interface FieldHelperTextProps extends WithStyles<typeof styles> {
  error?: boolean;
  warning?: boolean;
  errorText?: string;
  infoText?: string;
  warningText?: string;
}

const FieldHelperTextComponent: FC<FieldHelperTextProps> = ({
  classes,
  error = false,
  errorText = '',
  infoText = '',
  warning = false,
  warningText = '',
}) => {
  if (error && errorText) {
    return (
      <Flex autoWidth={false} alignItems="center" classes={{ root: cx(classes.root, classes.rootError) }} wrap="nowrap">
        <AlertCircleIcon color={colors.red.darkest} classes={{ root: classes.icon }} />
        <span className={classes.text}>{errorText}</span>
      </Flex>
    );
  }

  if (warning && warningText) {
    return (
      <Flex
        autoWidth={false}
        alignItems="center"
        classes={{ root: cx(classes.root, classes.rootWarning) }}
        wrap="nowrap"
      >
        <AlertTriangleIcon classes={{ root: classes.icon }} />
        <span className={classes.text}>{warningText}</span>
      </Flex>
    );
  }

  if (infoText) {
    return (
      <Flex autoWidth={false} alignItems="center" classes={{ root: cx(classes.root, classes.rootInfo) }} wrap="nowrap">
        <AlertCircleIcon
          color="#515D70" // TODO replace after creating new palette
          classes={{ root: classes.icon }}
        />
        <span className={classes.text}>{infoText}</span>
      </Flex>
    );
  }

  return null;
};

export const FieldHelperText = withStyles(styles)(FieldHelperTextComponent);
