import { Theme } from '@core/theme/types/main';
import { createStyles } from '@core/theme/utils/create-styles';

import { commonStyles } from '../Select.styles';

export function styles(theme: Theme) {
  return createStyles({
    ...commonStyles(theme),
    valueMultiple: {},
  });
}
