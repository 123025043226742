import { Theme } from '@core/theme/types/main';
import { colors } from '@core/theme/units/palette';
import { createStyles } from '@core/theme/utils/create-styles';

export const styles = ({ spacing }: Theme) =>
  createStyles({
    root: {},
    iconContainer: {
      marginBottom: spacing(4),
      height: 64,
      width: 64,
      borderRadius: 8,
    },
    success: {
      backgroundColor: '#ECFDF3', //TODO replace after creating new palette
    },
    warning: {
      backgroundColor: '#FFF5EB', //TODO replace after creating new palette
    },
    error: {
      backgroundColor: '#FEF3F2', //TODO replace after creating new palette
    },
    icon: {
      fontSize: 27,
    },
    heading: {
      marginBottom: spacing(2),
      fontSize: 20,
      fontWeight: 500,
      color: colors.black.darkest,
    },
    message: {
      marginBottom: spacing(5),
      color: '#515D70',
      fontSize: 14,
      fontWeight: 400,
      textAlign: 'center',
    },
    actionContainer: {},
    button: {
      marginRight: spacing(3),
    },
    additionalContent: {
      marginBottom: spacing(4),
    },
  });
