import { Menu, MenuItem } from '@material-ui/core';
import { FC, useMemo, useRef, useState } from 'react';

import { getBaseUrl } from '@core/base-url';
import { t } from '@core/i18n';
import { WithStyles, withStyles } from '@core/theme/utils/with-styles';
import { Avatar } from '@shared/components/avatar';
import { Flex } from '@shared/components/flex';
import { Link } from '@shared/components/link';
import ROUTES from '@shared/constants/routes';
import { AsIcon } from '@shared/icons/as';
import { DotsVerticalIcon } from '@shared/icons/dots-vertical';
import { LogoutIcon } from '@shared/icons/logout';
import { SettingsIcon } from '@shared/icons/settings';
import { UsersIcon } from '@shared/icons/users';
import { AppNav } from '@shared/models/config';
import { IUser } from '@shared/models/IUser';

import { styles } from './SideNavigationUserItem.styles';

export interface SideNavigationUserItemProps extends WithStyles<typeof styles> {
  user: IUser;
  appNav: AppNav;
}

const SideNavigationUserItemComponent: FC<SideNavigationUserItemProps> = ({ appNav, classes, user }) => {
  const [menuOpen, setMenuOpen] = useState<boolean>(false);
  const menuToggleRef = useRef<HTMLDivElement | null>(null);

  const optionsConfig = useMemo(() => {
    const options = [];

    if (appNav.marketplace_preferences_settings_menu) {
      options.push({
        icon: <AsIcon />,
        label: t('marketplace_preferences'),
        to: `${getBaseUrl()}#/society/preferences`,
      });
    }

    options.push({
      icon: <UsersIcon />,
      label: t('team'),
      to: `${getBaseUrl()}#/team/members`,
    });

    if (user.organisation?.id === 1) {
      options.push({
        icon: <i className={`icon icon-layers ${classes.layerIcon}`} />,
        label: t('style_guide'),
        to: ROUTES.styleGuide.root,
      });
    }

    options.push({
      icon: <SettingsIcon />,
      label: t('account_settings'),
      to: `${ROUTES.organisation.settings.root}${ROUTES.organisation.settings.profile}`,
    });

    options.push({
      icon: <LogoutIcon />,
      label: t('logout'),
      to: `${getBaseUrl()}logout`,
    });

    return options;
  }, [appNav, user]);

  const avatarContent = useMemo(
    () => (
      <Avatar
        classes={{
          root: classes.avatarRoot,
          avatar: classes.avatar,
          noImageInitials: classes.noImageInitials,
          noImage: classes.avatarNoImage,
        }}
        image={user.image.url}
        name={user.name}
        theme={user.theme}
      />
    ),
    [user]
  );

  const renderedOptions = useMemo(
    () =>
      optionsConfig.map((option, index) => (
        <div key={option.label}>
          {index === optionsConfig.length - 1 && <div className={classes.divider} />}
          <MenuItem classes={{ root: classes.menuItem }} disableRipple onClick={() => setMenuOpen(false)}>
            <Link to={option.to} classes={{ root: classes.itemLink }}>
              <Flex className={classes.itemIconContainer} alignItems="center">
                {option.icon}
              </Flex>
              <span className={classes.label}>{option.label}</span>
            </Link>
          </MenuItem>
        </div>
      )),
    [optionsConfig]
  );

  return (
    <>
      <div ref={menuToggleRef} onClick={() => setMenuOpen(true)} className={classes.root}>
        {avatarContent}
        <span className={classes.name}>{user.name}</span>
        <div className={classes.iconContainer}>
          <DotsVerticalIcon classes={{ root: classes.menuIcon }} />
        </div>
      </div>
      <Menu
        anchorEl={menuToggleRef.current}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        classes={{ paper: classes.menuPaper, list: classes.menuList }}
        open={menuOpen}
        onClose={() => setMenuOpen(false)}
      >
        {renderedOptions}
      </Menu>
    </>
  );
};

export const SideNavigationUserItem = withStyles(styles)(SideNavigationUserItemComponent);
