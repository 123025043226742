import { Tooltip as MuiTooltip, TooltipProps as MuiTooltipProps } from '@material-ui/core';

import { withStyles, WithStyles } from '@core/theme/utils/with-styles';

import { styles } from './Tooltip.styles';

export interface TooltipProps extends Omit<MuiTooltipProps, 'classes'>, WithStyles<typeof styles> {
  containerProps?: React.HTMLAttributes<HTMLDivElement>;
}

const TooltipComponent: React.FC<TooltipProps> = ({
  arrow = true,
  children,
  classes,
  containerProps,
  onClick,
  placement,
  title,
  ...otherProps
}) => {
  return (
    <MuiTooltip
      {...otherProps}
      arrow={arrow}
      placement={placement}
      title={title}
      className={classes.root}
      classes={{
        tooltip: classes.tooltip,
        arrow: classes.arrow,
      }}
    >
      <div {...containerProps} className={classes.container}>
        {children}
      </div>
    </MuiTooltip>
  );
};

export const Tooltip = withStyles(styles)(TooltipComponent);
