import { createStyles } from '@core/theme/utils/create-styles';

export const styles = () =>
  createStyles({
    root: {},

    filtersIcon: {
      fontSize: '12px !important',
      marginBottom: 3,
    },
    sourceIcon: {
      fontSize: '16px  !important',
      marginBottom: 3,
    },
    teamsIcon: {
      fontSize: '12px !important',
      marginBottom: 3,
    },

    counterRoot: {
      width: 17,
      height: 17,
      minWidth: 17,
    },
    counterCount: {
      fontSize: 11,
    },
  });
