import { Link } from '@material-ui/core';
import { FC } from 'react';

import { WithStyles, withStyles } from '@core/theme/utils/with-styles';
import { Flex } from '@shared/components/flex';
import { LogoImage } from '@shared/components/side-navigation/components/logo/logo-image';
import { Config } from '@shared/models/config';

import { styles } from './RxNavigationLogo.styles';

export interface RxNavigationLogoProps extends WithStyles<typeof styles> {
  appLogos: Config['appLogos'];
  katoUrl: string;
  onClickLogo: () => void;
}

const RxNavigationLogoComponent: FC<RxNavigationLogoProps> = ({ classes, appLogos, katoUrl, onClickLogo }) => {
  return (
    <Flex autoWidth={false} direction="column" classes={{ root: classes.root }}>
      <LogoImage appLogos={appLogos} classes={{ root: classes.logo }} onClick={onClickLogo} />
      <span className={classes.poweredBy}>
        Powered by
        <Link href={katoUrl} classes={{ root: classes.poweredByLink }} target="_blank">
          Kato
        </Link>
      </span>
    </Flex>
  );
};

export const RxNavigationLogo = withStyles(styles)(RxNavigationLogoComponent);
