import { Theme } from '@material-ui/core';

import { colors } from '@core/theme/units/palette';
import { alpha } from '@core/theme/utils/alpha';
import { createStyles } from '@core/theme/utils/create-styles';

export const styles = ({ spacing }: Theme) =>
  createStyles({
    root: {
      width: 118,
      gap: 4,
    },
    logo: {
      cursor: 'pointer',
    },
    poweredBy: {
      color: alpha(colors.white.lightest, 0.6),
      fontSize: 10,
      fontWeight: 500,
      lineHeight: 1,
      userSelect: 'none',
    },
    poweredByLink: {
      color: colors.white.lightest,
      paddingLeft: spacing(1),
    },
  });
